import logo from './logo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react'

import { Container } from '@chakra-ui/react'

import { Box } from '@chakra-ui/react'

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Badge,
} from '@chakra-ui/react'
import { Button, ButtonGroup, Text } from '@chakra-ui/react'

import { useState } from 'react';

function App() {
  const [text, setText] = useState('');
  const [submittedText, setSubmittedText] = useState('');
  const [submittedText_standard, setSubmittedText_standard] = useState('');
  const [submittedText_nonstandard, setSubmittedText_nonstandard] = useState('');

  const [StandardPort] = useState({
    // "HTTP": ["80"],
    "HTTPS": ["443"],
    // "FTP": ["20", "21"],
    "FTPS": ["990"],
    "Secure FTP": ["22"],
    "SSH": ["22"],
    "TFTP": ["69"],
    "SNMP": ["161", "162"],
    "H.323": ["1718", "1719", "1720"],
    "SIP": ["5060", "5061"],
    "SAP Common Port": ["3200-3299", "3300-3388", "3390-3399", "3600-3699"],
    "SQL": ["1433", "1434", "1435"],
    "Oracle": ["1521"],
    "MySQL": ["3306"],
    "SAP Printer": ["515"]
  });

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = () => {
    const ports = new Set();
    const standardPorts = new Set();
    const nonStandardPorts = new Set();

    // แยกข้อความตามบรรทัด
    const lines = text.split('\n');

    lines.forEach(line => {
      // หาพอร์ตที่มีการเขียนเป็นตัวเลขหรือช่วง
      const matches = line.match(/\d{1,5}(-\d{1,5})?\b/g);
      console.log("Ayo");
      console.log(matches);
      if (matches) {
        matches.forEach(port => {
          if (port.includes('-')) {
            // ถ้าเป็นช่วงพอร์ต (เช่น 11000-11999) แยกเป็นแต่ละพอร์ต
            const [start, end] = port.split('-').map(Number);
            for (let i = start; i <= end; i++) {
              ports.add(i);
            }
          } else {
            // ถ้าเป็นพอร์ตเดี่ยวๆ ใส่เข้าไปใน Set
            ports.add(Number(port));
          }
        });
      }
    });

    // แปลง Set ให้เป็น array แล้วเรียงลำดับ
    const sortedPorts = Array.from(ports).sort((a, b) => a - b);

    // ฟังก์ชันรวมช่วงพอร์ตที่ต่อเนื่องกัน
    const combinePorts = (portArray) => {
      const result = [];
      if (Array.isArray(portArray) && portArray.length === 0) {
        return result; // หรือ return null / return undefined ตามต้องการ
      }
      let start = portArray[0];
      let end = portArray[0];

      for (let i = 1; i < portArray.length; i++) {
        if (portArray[i] === end + 1) {
          end = portArray[i];
        } else {
          if (start === end) {
            result.push(start.toString());
          } else {
            result.push(`${start}-${end}`);
          }
          start = portArray[i];
          end = portArray[i];
        }
      }

      // เพิ่มช่วงสุดท้าย
      if (start === end) {
        result.push(start.toString());
      } else {
        result.push(`${start}-${end}`);
      }

      return result;
    };

    // ตรวจสอบพอร์ตที่เป็นมาตรฐานและไม่มาตรฐาน
    sortedPorts.forEach(port => {
      let isStandard = false;

      // ตรวจสอบพอร์ตใน StandardPort
      for (const key in StandardPort) {
        StandardPort[key].forEach(p => {
          if (p.includes('-')) {
            // ถ้าเป็นช่วง ให้ตรวจสอบว่าพอร์ตอยู่ในช่วงหรือไม่
            const [start, end] = p.split('-').map(Number);
            if (port >= start && port <= end) {
              isStandard = true;
              standardPorts.add(port);
            }
          } else if (Number(p) === port) {
            isStandard = true;
            standardPorts.add(port);
          }
        });
      }

      if (!isStandard) {
        nonStandardPorts.add(port);
      }
    });

    // รวมช่วงพอร์ตที่ต่อเนื่องกัน
    const allPortArray = combinePorts(sortedPorts);
    const standardPortArray = combinePorts(Array.from(standardPorts).sort((a, b) => a - b));
    const nonStandardPortArray = combinePorts(Array.from(nonStandardPorts).sort((a, b) => a - b));

    // แสดงผลลัพธ์พอร์ตที่รวมแล้ว
    setSubmittedText(allPortArray.join(', '));
    setSubmittedText_standard(standardPortArray.join(', '));
    setSubmittedText_nonstandard(nonStandardPortArray.join(', '));
  };
  return (
    <Container maxW="container.xl" mt={3}>
      <FormControl 
        borderWidth='1px'
        borderRadius='lg'
        p={3}
      >
        <FormLabel>Port</FormLabel>
        <Textarea
          placeholder="Here is a sample placeholder"
          value={text}
          onChange={handleChange}
          h={"300px"}
        />
        {/* <FormHelperText>Transform Port</FormHelperText> */}
        <Button mt={4} colorScheme="teal" type="submit" onClick={handleSubmit}>
          Submit
        </Button>
        
      </FormControl>
      <Badge ml='1' colorScheme='purple'>Updated V1.2 มาแก้บัคบางทีก็ค้าง port ไม่ออก</Badge>

      {submittedText && (
        <Box
          borderWidth='1px'
          borderRadius='lg'
          mt={3}
          p={3}
        >
          <Text color="teal.500">
            {submittedText_standard && `Standard Ports: ${submittedText_standard}`} 
            {submittedText_nonstandard && ` Non-Standard Ports: ${submittedText_nonstandard}`}
          </Text>
        </Box>
      )}

      {submittedText_standard && (
        <Box
          borderWidth='1px'
          borderRadius='lg'
          mt={3}
          p={3}
        >
          <Text color="teal.500">
            Standard Ports: {submittedText_standard}
          </Text>
        </Box>
      )}
      {submittedText_nonstandard && (
        <Box
          borderWidth='1px'
          borderRadius='lg'
          mt={3}
          p={3}
        >
          <Text color="teal.500">
            Non-Standard Ports: {submittedText_nonstandard}
          </Text>
        </Box>
      )}
      {submittedText && (
        <Box
          borderWidth='1px'
          borderRadius='lg'
          mt={3}
          p={3}
        >
          <Text color="teal.500">
            All Ports: {submittedText}
          </Text>
        </Box>
      )}
    </Container>
  );
}

export default App;
